<template>
  <transition name="modal-fade">
    <div ref="modal" class="modal-backdrop" @click="clickOutside">
      <div class="modal" role="dialog">
        <div class="btn-close" @click="close">
          <md-icon>close</md-icon>
        </div>

        <h4>Изменить премиум доступ</h4>

        <ValidationObserver
          ref="updatePremiumForm"
          class="md-layout md-gutter"
          tag="div"
        >
          <div class="md-layout-item md-large-size-40">
            <md-checkbox v-model="isPremium"> Включить </md-checkbox>
          </div>

          <ValidationProvider
            v-if="isPremium"
            v-slot="{ errors, reset }"
            class="md-layout-item md-large-size-60"
            mode="passive"
            tag="div"
          >
            <md-datepicker
              v-model="premiumEndDate"
              :md-model-type="String"
              :class="{ error: errors.length }"
              @md-opened="reset"
            >
              <ValidationError :errors="errors" />
              <label>Дата окончания</label>
            </md-datepicker>
          </ValidationProvider>
        </ValidationObserver>

        <div class="buttons-wrap">
          <md-button
            class="md-raised md-success"
            :disabled="isLoaderVisible"
            @click="save"
          >
            Сохранить
          </md-button>

          <md-button class="md-raised md-danger" @click="close">
            Отменить
          </md-button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      isPremium: false,
      premiumEndDate: null,
    };
  },

  computed: {
    ...mapGetters("users", ["usersById"]),
    ...mapState(["isLoaderVisible"]),
  },

  mounted() {
    this.isPremium = this.usersById[this.userId]?.isPremium || null;
    this.premiumEndDate = this.usersById[this.userId]?.premiumEndDate || null;
  },

  methods: {
    ...mapActions("users", ["setUserPremium"]),
    ...mapMutations({
      showLoader: "SET_SHOW_LOADER",
    }),

    clickOutside(e) {
      if (e.target.classList.contains("modal-backdrop")) {
        this.close();
      }
    },

    close(needUpdate = false) {
      this.$emit("close", needUpdate);
    },

    async save() {
      const isValid = await this.$refs.updatePremiumForm.validate();
      if (!isValid) {
        return;
      }

      let payload = {
        userId: this.userId,
        isPremium: this.isPremium,
      };

      if (this.isPremium) {
        payload.premiumEndDate = new Date(this.premiumEndDate).toISOString();
      }

      this.showLoader(true);
      try {
        await this.setUserPremium(payload);

        this.close(true);
      } catch (error) {
        this.showLoader(false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-backdrop {
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 10;

  .modal {
    position: relative;
    height: auto;
    padding: 20px;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 5px;
    background: #fff;
  }

  .btn-close {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    font-size: 20px;
    color: #c4c4c4;
  }
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

.buttons-wrap {
  display: flex;

  & > * {
    &:first-child {
      margin-right: 8px;
    }

    &:last-child {
      margin-left: 8px;
    }
  }
}
</style>
